<template>
  <div class="user-agreement">
    <h1>用户协议</h1>
    <div class="agreement-content" v-html="agreementText"></div>
  </div>
</template>

<script>
export default {
  name: 'AppTermOfService',
  data() {
    return {
      agreementText: `
欢迎使用我们的服务！请仔细阅读以下条款，如果您同意这些条款，请继续使用我们的服务。

1. 服务条款的接受
通过访问或使用我们的网站，您表示同意接受本协议的所有条款和条件。

2. 服务的变更
我们保留随时更改或中断服务的权利，恕不另行通知。

3. 用户行为
用户不得使用我们的服务进行任何非法活动。

4. 隐私政策
请参阅我们的隐私政策以了解我们如何收集、使用和保护您的个人信息。

5. 免责声明
我们在任何情况下均不对因使用或无法使用我们的服务而引起的任何损害承担责任。

6. 法律适用与管辖
本协议受中华人民共和国法律管辖并按其解释。

7. 协议的修改
我们保留随时修改本协议的权利，恕不另行通知。

8. 联系我们
如果您对本协议有任何疑问，请联系我们：support@example.com
`
    };
  }
}
</script>

<style scoped>
.user-agreement {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

h1 {
  color: #333;
  margin-top: 0;
}

.agreement-content {
  color: #666;
  line-height: 1.6;
  word-wrap: break-word;
  white-space: pre-line;
  margin: 20px 0;
}

.agreement-content h2 {
  color: #333;
  margin: 20px 0 10px;
}

.agreement-content p {
  margin: 0 0 15px;
}

@media (max-width: 600px) {
  .user-agreement {
    padding: 10px;
  }

  .agreement-content {
    font-size: 16px;
  }

  .agreement-content h2 {
    font-size: 20px;
  }
}
</style>