<template>
    <div class="StationCode">
      <h1>网点列表</h1>
      <table>
        <thead>
          <tr>
            <th>网点ID</th>
            <th>网点名称</th>
            <th>手机号</th>
            <th>导出二维码</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="station in stations" :key="station.id">
            <td>{{ station.id }}</td>
            <td>{{ station.name }}</td>
            <td>{{ station.phone }}</td>
            <!-- <td><img :src="station.qrcode_url" alt="站点二维码"></td> -->
            <td><a @click="downloadQrCode('https://cos.gxlctl.com/official/qrcode/49/f981ceb7c4f7774f9e7f53b4c7cb2b.jpg')">导出二维码</a></td>

          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'StationCode',
    data() {
      return {
        stations: [],
        downUrl:''
      };
    },
    created() {
      this.fetchStations();
    },
    methods: {
      async fetchStations() {
        try {
          const response = await axios.get('https://api.gxlctl.com/home/index/station');
          if (response.data.code === 0 && response.data.success) {
            const stations = response.data.data;
            // for (let station of stations) {
            //   const qrcodeResponse = await axios.get(`https://api.gxlctl.com/home/index/qrcode/${station.id}`);
            //   if (qrcodeResponse.data.code === 0 && qrcodeResponse.data.success) {
            //     station.qrcode_url = qrcodeResponse.data.data.qrcode_url;
            //   }
            // }
            this.stations = stations;
          } else {
            console.error('获取网点列表失败:', response.data.msg);
          }
        } catch (error) {
          console.error('请求失败:', error);
        }
      },
      downloadQrCode(url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = 'qrcode.png'; // 设置下载的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    }
  };
  </script>
  
  <style scoped>
#StationCode {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 60px;
    padding: 0 20px; /* 添加内边距，防止内容紧贴边缘 */
    overflow-x: auto; /* 添加水平滚动条 */
    max-height: 500px; /* 设置最大高度，以启用垂直滚动 */
    overflow-y: auto; /* 启用垂直滚动 */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0; /* 调整上下边距 */
    min-width: 800px; /* 设置最小宽度，防止内容过窄 */
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold;
    position: sticky; /* 固定表头 */
    top: 0; /* 表头距离顶部的距离 */
    z-index: 1; /* 确保表头在其他内容之上 */
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #ddd;
  }
  </style>