<template>
    <div class="user-agreement">
     
用户服务协议

请认真阅读并理解以下内容，其中以加粗方式显著标识的文字，请着重阅读、慎重考虑。 本协议是您与《乐村淘供配》所有者之间所订立的契约，主体运营方公司（广西乐村淘网络科技有限公司），客服电话：0771-2261045。请您仔细阅读本协议，您一经勾选“我同意《乐村淘供配用户服务协议》（点击下载）”点击"同意并继续"按钮后，本协议即构成对双方有约束力的法律文件。

第 1 条 相关定义
1.1 乐村淘供配平台：我们是通过提供高品质的服务，让合作商或终端门店向供货商或者厂商进行采购变得更加省钱、高效、易管理的网上商城采购平台。

第 2 条 本网站服务条款的确认和接纳
2.1 本网站所提供的乐村淘供配会员活动的所有权和运作权归广西乐村淘网络科技有限公司所有。
2.2 您点击同意本协议的，即视为您确认自己同意接受乐村淘供配会员相关服务的条款，且同意按本协议内容履行，如产生相关责任的，同意承担相应法律责任。
2.3 乐村淘供配保留在中华人民共和国大陆地区之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。
2.4 乐村淘供配会员开通后，您在已开通的服务期内中途主动取消服务或终止资格的，将不获得为开通本服务而支付费用的退还。

第 3 条 会员资格（申请成为合作门店/申请成为县域运营商）
3.1 乐村淘供配仅针对零售门店和县域合作商采购商品的网上商城平台，用户完成认证且审核通过的情况下，即享有乐村淘供配会员参与资格，并可享受乐村淘供配网上商城所提供的相关活动服务。

第 4 条 会员权益您成为乐村淘供配商城正式会员后，可享受的权益以乐村淘供配商城官方网站公布的信息为准。为更好的向会员提供服务，乐村淘供配商城有权基于自身业务发展需要调整全部或部分会员权益。乐村淘供配商城就前述权益调整将在相应服务页面进行通知或公告，您可以通过乐村淘供配商城官方网站查询最新的会员权益内容。 如您希望享有广西乐村淘网络科技有限公司之外第三方提供的相关会员权益，本网站需要与第三方共享为您提供服务涉及的必要信息，以便更好的为您提供服务。

第 5 条 商品信息
本网站上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本网站不作特别通知。由于网站上商品信息的数量极其庞大，虽然本网站会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本网站网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；乐村淘供配商城欢迎纠错，并会视情况给予纠错者一定的奖励。为表述便利，商品和服务简称为“商品”或“货物”。
第 6 条 订单
6.1 在您使用本网站下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规 格、尺寸、联系地址、电话、收货人等信息。收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。
6.2 您理解并同意：本网站上销售商展示的商品和价格等信息仅仅是要约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（履行地点）、履行方式等内容；如果您在一份订单里订购了多种商品并且只给您发出了部分商品时，您与乐村淘供配商城之间仅就实际直接向您发出的商品建立了关系，只有在乐村淘供配商城实际直接向您发出了订单中订购的其他商品时，您和乐村淘供配商城之间就订单中其他已实际直接向您发出的商品才成立关系；对于电子书、数字音乐、在线手机充值等数字化商品，您下单并支付货款后即成立。当您作为采购者需要下单并支付货款的情况下，您货款支付成功后即视为您与乐村淘供配商城之间就已支付货款部分的订单建立了关系。
6.3 尽管乐村淘供配商城做出最大的努力，但由于市场变化及各种以合理商业努力难以控制因素的 影响，本网站无法避免您提交的订单信息中的商品出现缺货、价格标示错误等情况；如您下单所购买的商品出现以上情况，您有权取消订单，销售商亦有权自行取消订单，若您已经付款，则为您办理退款。
第 7 条 配送和交付
7.1 您在本网站购买的商品将按照本网站上您所指定的送货地址进行配送。订单信息中列出 的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送 货地点的基础上估计得出的。您应当清楚准确地填写您的送货地址、联系人及联系方式等配 送信息，您知悉并确认，您所购买的商品应仅由您填写的联系人接受身份查验后接收商品， 因您变更联系人或相关配送信息而造成的损失由您自行承担。
7.2 因如下情况造成订单延迟或无法配送等，本网站将无法承担迟延配送或无法配送的责 任：（1）客户提供错误信息和不详细的地址；（2）货物送达无人签收或拒收，由此造成的重复配送所产生的费用及相关的后果。（3）不可抗力，例如：自然灾害及恶劣天气、交通戒严等政府、司法机关的行为、决定或命令、意外交通事故、罢工、法规政策的修改、恐怖事件、抢劫、抢夺等暴力犯罪、突发战争等。
7.3 乐村淘供配商城依您订单确定的收货信息配送至指定地点并向指定收货人交付，商品一经签收即视为交付，您应当场对商品品类、规格、型号、数量和包装等商品表面状况进行验收。您应及时收取货物。如您对商品质量有异议的，应于商品交付后五日内向乐村淘供配商城提出，乐村淘供配商城根据实际情况及时处理。
7.4 您在本网站购买的商品由乐村淘供配商城的关联方或第三方配送公司（包括顺丰、圆通等，以下称 “配送公司”）为您完成订单交付的，系统或单据记录的签收时间为交付时间；您购买的商品采用在线传输方式交付的，乐村淘供配商城向您指定系统发送的时间为交付时间；您购买服务的，生成的电子或者实物凭证中载明的时间为交付时间。
第 8 条 售后服务乐村淘供配商城依据国家相关规定、厂家规定提供相关售后服务。
第 9 条 结算方式和发票
9.1 您在平台提交有效采购订单，结算方式应在平台提示的结算方式范围内自主选择。
9.2 您应保证提交的开票信息及付款信息的真实性与准确性，如您不是实际付款方，销售 商将默认您与实际付款方就订单代付达成一致，否则造成的一切损失由您自行承担，给销 售方造成实际损失的，您应承担相应赔偿责任。
9.3 您收到发票后应及时跟销售方确认发票送达情况，超过7日未核实，则视为您已确认 收到发票。您应及时就已要求开具增值税专用发票进行认证抵扣，如因您的原因超期未抵扣，由此产生全部责任或损失均由您承担。
9.4 您如果成为县域合作运营商，则平台订单产生的销售款项需作为采购进货使用，如发现挪用销售货款而未能正常开展商品供货服务，由此产生全部责任或损失均由您承担。
第 10 条 违约责任
10.1 针对您的批量采购或定制商品需求，销售方开展备货行为后，如您未经乐村淘供配商城书面同意擅自取消采购需求或订单等违约行为，由此产生全部责任或损失均由您承担。
10.2 您应妥善保管本协议项下账户名及密码信息，如因为您擅自将账户名及密码信息转让、授权、赠与或因保管不善等任何其它原因导致任何其它人使用的，您需自行对其账户项下的一切行为独立承担责任。同时本网站在该种情况下有权做出独立判断，可采取暂停或关闭您参与资格等措施。
10.3 您采购的商品不得低价销售给任何其他分销商或经销商，若产生与任何本协议外第三方的争议均由您自行解决，给乐村淘供配商城造成实际损失的，您应承担相应赔偿责任。
10.4 如果乐村淘供配商城发现或收到他人举报投诉您违反本协议约定或存在任何恶意行为的，乐村淘供配商城有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁、注销等处罚，并公告处理结果。
10.5 除非另有明确的书面说明,乐村淘供配商城不对本网站的运营及其包含在本网站上的信息、内 容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
第 11 条 所有权及知识产权条款
11.1 用户一旦接受本协议，即表明该用户主动将其在任何时间段在本网站发表的任何形式 的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利 等任何可转让的权利，如著作权财产权（包括并不限于复制权、发行权、出租权、展览权、 表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由 著作权人享有的其他可转让权利），全部独家且不可撤销地转让给乐村淘供配商城所有，用户同意乐村淘供配商城有权就任何主体侵权而单独提起诉讼。
11.2 本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照 2011 年版著 作权法确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在广西乐村淘网络科技有限公司网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
11.3 用户同意并已充分了解本协议的条款，承诺不将已发表于本网站的信息，以任何形式 发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。
11.4 广西乐村淘网络科技有限公司是本网站的制作者，拥有此网站内容及资源的著作权等合法权利，受国家法律保护，有权不时地对本协议及本网站的内容进行修改，并在本网站张贴，无须另行通知用户。
11.5 除法律另有强制性规定外，未经广西乐村淘网络科技有限公司明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本网站的信息内容，否则，广西乐村淘网络科技有限公司有权追究其法律责任。
11.6 本网站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、 数字下载、数据编辑和软件），均是广西乐村淘网络科技有限公司或其内容提供者的财产，受中国和国际版权法的保护。本网站上所有内容的汇编是广西乐村淘网络科技有限公司的排他财产，受中国和国际版权法的保护。本网站上所有软件都是广西乐村淘网络科技有限公司或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。
第 12 条 责任限制及不承诺担保
12.1 如果乐村淘供配商城发现或收到他人举报投诉您违反本协议约定或存在任何恶意行为的，乐村淘供配商城权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁、注销等处罚，并公告处理结果。 

12.2 乐村淘供配商城有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
12.3 您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任 何索赔、要求或损失，您应当独立承担责任；乐村淘供配商城因此遭受损失的，您也应当一并赔偿。
12.4 除非另有明确的书面说明，本网站及其所包含的或以其它方式通过本网站提供给您的 全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。除非另有明确的书面说明，乐村淘供配商城不对本网站的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的除外）。 乐村淘供配商城不担保本网站所包含的或以其它方式通过本网站提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本网站发出的电子信件、信息没有病毒或其他有害成分。如因不可抗力或其它本网站无法控制的原因使本网站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，乐村淘供配商城会合理地尽力协助处理善后事宜。
第 13 条 会员使用注意事项
13.1 提交申请开通时，您同意并提交本人手机号码和本网站所需的企业资料，并保证其提供的注册资料真实、准确、完整、合法有效，您的注册资料如有变动的，应及时更新其注册资料。如果您提供的注册资料不合法、不真实、不准确、不详尽，您需承担因此引起的相应责任及后果，并且广西乐村淘网络科技有限公司保留终止您参与该活动的权利。
13.2 您同意，广西乐村淘网络科技有限公司拥有通过邮件、短信电话等形式，向其发送相关活动信息等必要信息的权利。
13.3 您同意，其应合理使用其享有的会员权益，除为实现您自身的会员利益外，不得利用 其享有的权益非法获利，不得以任何形式售卖其所享有的权益，不得滥用其所享有的权益，如因您不当行为致乐村淘供配商城合理怀疑的或判定的，乐村淘供配商城有权暂停或关闭您该活动的权益，此外，您需承担因此引起的相应责任及后果。
第 14 条 保密义务
14.1 本协议任一方（以下简称“获取方”）对于本协议的签订、内容及在履行本协议期间所 获知的相对方（以下简称“披露方”）的商业秘密负有保密义务。非经披露方书面同意，不得向任何第三方泄露、给予或转让该等保密信息。（依据法律法规、证券交易所规则向政府、 证券交易所和/或其他监管机构提供、协议方的法律、会计、商业及其他顾问、雇员等提供的除外）。
14.2 如披露方提出要求，获取方应将单独载有披露方保密信息的任何文件、资料或软件等， 在本协议终止后按照披露方的要求归还、销毁，或进行其他处置，不得继续使用或复制、留 存。
14.3 本协议履行期间及终止后，双方均需履行本协议项下保密义务，直至披露方同意获取 方解除此项义务，或事实上不会因获取方违反本协议的保密条款而给披露方造成任何形式的 损害时止，包括但不限于保密信息已经由披露方向公众披露的情形等。
14.4 协议双方均应告知并督促其因履行本协议之目的而必须获知本协议内容及因合作而获 知对方商业秘密的雇员、代理人等遵守保密条款，并对其雇员、代理人等的行为承担连带责 任。
第 15 条 通知与送达
本协议履行过程中，一方传递给另一方的书面通知；或在诉讼程序中，法院对双方进行书面通知的，按照您注册时填写的地址进行送达。书面通知的形式还包括但不限于电子邮 件、手机短信和传真等电子方式，在采用电子方式进行书面通知的情况下发送即视为送达。一方的送达地址或送达电子邮箱需要变更时应当履行通知义务，通过信件/邮件/电话/ 短信的方式对另一方进行通知。
第 16 条 协议更新及用户关注义务
根据国家法律法规变化及本网站运营需要，乐村淘供配商城有权对本协议条款及相关规则不时地进行修改，修改后的内容一旦以任何形式公布在本网站上即生效，并取代此前相关内容，您应不时关注本网站公告、提示信息及协议、规则等相关内容的变动。您知悉并确认，如您不同意更新后的内容，应立即停止使用本网站；如您继续使用本网站，即视为知悉变动内容并同意接受。乐村淘供配商城建议您在使用本网站之前阅读本协议及本网站的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
第 17 条 法律管辖和适用本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，应向协议签订地有管辖权的人民法院提起诉讼。本协议签订地为中华人民共和国南宁市良庆区。
第 18 条 其他
18.1 乐村淘供配商城网站所有者是指在政府部门依法许可或备案的广西乐村淘网络科技有限公司网站经营主体。
18.2 如因不可抗力或其它本网站无法控制的原因使本网站活动服务无法及时提供或无法按 本协议进行的，乐村淘供配商城会合理地尽力协助处理善后事宜。
18 .3 正式乐村淘供配商城会员自您购买之日起开始计算至到期当日自动终止。
18.4 本协议内容中以黑体、加粗、下划线、斜体等方式显著标识的条款，请您着重阅读。
18.5 您点击本协议下方的“同意并继续”按钮即视为您完全接受本协议，在点击之前请您 再次确认已知悉并完全理解本协议的全部内容。
18.6 本协议附件为本协议不可分割的组成部分，与本协议具有同等法律效力。附件：《反商业贿赂协议》双方合作期间，为了更严格遵守《反不正当竞争法》及其他相关法律法规有关禁止商业贿赂行为的规定，维护双方共同利益，促进双方关系良好发展，经双方友好协商，达成如下协议：
第一条 本协议中的商业贿赂是指您为谋求交易机会或竞争优势及其他合作的利益，您或您 单位工作人员或您通过第三方给予广西乐村淘网络科技有限公司员工及广西乐村淘网络科技有限公司员工利害相关人的一切物质及精神上的直接或间接的不正当利益。
第二条 不正当利益：包括物质性利益和非物质性利益。物质性利益是指能够直接用金钱价 值加以衡量的利益。包括但不限于回扣、贿赂、私下佣金、借款、实物、现金或现金等价物 （如：消费卡/券、提货券、购物卡、换购券、充值卡、交通卡、电话卡、各种话费的充值或其它可供使用或消费的充值、储值卡及其它形态的有价礼券或证券等）、支票及财产性权 益、旅游、宴请、免费消费。非物质性利益是指难以直接用经济或金钱价值加以衡量的能满 足人们需求和欲望的精神利益和其他不正当利益，是物质性利益以外的权益、优惠、便利以 及其它好处。包括但不限于给予解决住房机会、迁移户口、调动工作、提拔职务、安排出国 留学、享受免费的服务等方面的利益，以及给予荣誉、名誉、称号、资格、地位、特权。
第三条 利益冲突：包括但不限于（1）您不得向广西乐村淘网络科技有限公司员工及其利害相关人提供任何形式的借款；（2）您的股东、监事、经理、高级管理人员、合作项目负责人及项目成员系广西乐村淘网络科技有限公司员工或其利害相关人员的，应在合作前以书面方式如实、全面告知广西乐村淘网络科技有限公司。（3）双方合作过程中，您不得允许广西乐村淘网络科技有限公司员工及其配偶持有或由第三方代持有您股权（但通过公开的证券交易市场且低于发行在外 5%的权益、通过直接或间接持有无实际控制权的基金、或通过受益人非本人或利益代言人的信托方式持有的股份除外），亦不得聘用广西乐村淘网络科技有限公司员工（包括但不限于建立正式 劳动关系、劳务派遣、外包服务、兼职、咨询顾问等其他形式）。（4）您不得雇佣广西乐村淘网络科技有限公司辞退的人员或自广西乐村淘网络科技有限公司主动离职不到1年的人员对接广西乐村淘网络科技有限公司业务。
第四条 若您违反上述约定行为之一，广西乐村淘网络科技有限公司有权单方部分或全部终止与您的合同，同时您应向广西乐村淘网络科技有限公司支付10万元违约金或者支付合作期间订单（合同）金额的 50%作为违约金，两者以高者为准。您应于广西乐村淘网络科技有限公司发现违约行为之日起5个工作日内支付违约金，如未及时支付，广西乐村淘网络科技有限公司有权从合同款项中直接扣除。同时，您及其实际控制、代理的或协助您业务的公司将被列入失信名单，即为永不合作的供应商。
第五条 若您违反本协议第三条第（2）款和/或第（3）款、第（4）款之规定，除应根据上述第四条承担违约金，您还应将因此行为所得的全部收益支付给广西乐村淘网络科技有限公司。您应于广西乐村淘网络科技有限公司发现该等违约行为之日起5个工作日内向广西乐村淘网络科技有限公司支付其所得的全部收益，如未及时支付，广西乐村淘网络科技有限公司有权从合同款项中直接扣除，不足部分广西乐村淘网络科技有限公司有权向您进行追偿。
第六条 对于您，无论是主动还是被动发生第一条、第二条、第三条所示行为的，如果主动向广西乐村淘网络科技有限公司提供有效信息，广西乐村淘网络科技有限公司将与您继续合作并给予相应的奖励，对于上述情形的处理广西乐村淘网络科技有限公司有完全的判断权和自主权，您认可并自愿接受处理结果。
第七条 若您有知悉/怀疑广西乐村淘网络科技有限公司员工有违反上述规定的，欢迎与广西乐村淘网络科技有限公司内控合规部联系。信息提供者提供的有关商业贿赂行为的信息一经查实，广西乐村淘网络科技有限公司将根据事件的影响程度给予信息提供者 500 元至 5000 元人民币的现金奖励或相应广告、促销等资源类奖励（最低不少于人民币 500 元）。广西乐村淘网络科技有限公司设定专用邮箱接受您的投诉电话： 。广西乐村淘网络科技有限公司会对所有信息提供者及所提供的全部资料严格保密。
    </div>
  </template>
  
  <script>
  export default {
    name: 'UserAgreement',
    data() {
      return {
        agreementText:""     };
    }
  }
  </script>
  
 