<template>
    <div>
      <h1>App 下载数据统计</h1>

    
    <div id="statistics">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>用户 IP</th>
            <th>地址</th>
            <th>点击次数</th>
            <th>首次点击时间</th>
            <th>最后点击时间</th>
            <th>
            <button @click="exportToCSV" class="export-button">导出数据</button>
          </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in statisticsData" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.user_ip }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.click_count }}</td>
            <td>{{ item.first_click_time }}</td>
            <td>{{ item.last_click_time }}</td>
            <td>/</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'AppStatistics',
    data() {
      return {
        statisticsData: []
      };
    },
    created() {
      this.fetchStatistics();
    },
    methods: {
      async fetchStatistics() {
        try {
          const response = await axios.get('https://app.statistics.gxlctl.com/get');
          this.statisticsData = response.data;
        } catch (error) {
          console.error('Error fetching statistics:', error);
        }
      },
    exportToCSV() {
      const csvContent = this.convertToCSV(this.statisticsData);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'statistics.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    convertToCSV(data) {
      const keys = Object.keys(data[0]);
      const csvContent = [keys.join(','), ...data.map(row => keys.map(k => `"${row[k]}"`).join(','))].join('\n');
      return csvContent;
    }
    }
  };
  </script>
  
  <style scoped>
  #statistics {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 60px;
    padding: 0 20px; /* 添加内边距，防止内容紧贴边缘 */
    overflow-x: auto; /* 添加水平滚动条 */
    max-height: 500px; /* 设置最大高度，以启用垂直滚动 */
    overflow-y: auto; /* 启用垂直滚动 */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0; /* 调整上下边距 */
    min-width: 800px; /* 设置最小宽度，防止内容过窄 */
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold;
    position: sticky; /* 固定表头 */
    top: 0; /* 表头距离顶部的距离 */
    z-index: 1; /* 确保表头在其他内容之上 */
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #ddd;
  }
  </style>